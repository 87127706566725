import { fetchListToken } from 'utilities/services';
import Rifi from '@rikkei-finance/rifi-js';

const SET_DATA_TOKENS = 'tokens/SET_DATA_TOKENS';

export const initialState = {
  tokensBlock: undefined,
  tokensCurrent: undefined,
};

export const setDataTokens = (data) => ({
  type: SET_DATA_TOKENS,
  payload: {
    tokensBlock: data.tokensBlock,
    tokensCurrent: data.tokensCurrent,
  },
});

export const fetchAllTokens = (chainId) => async (dispatch, getState) => {
  const network = Rifi.util.getNetNameWithChainId(chainId);
  const promises = [
    fetchListToken({ network }),
    fetchListToken({
      meta: true,
      network: network,
      block_timestamp: Math.ceil(Date.now()) - 86400000, // timestamp from 1970
      // block_timestamp: 1618204146,
    }),
  ];
  Promise.all(promises)
    .then(function (result) {
      // console.log('promise all fetchAllTokens', result);
      if (result && result.length === 2) {
        dispatch(
          setDataTokens({ tokensBlock: result[1], tokensCurrent: result[0] }),
        );
      }
      return true; // return from here to go to the next promise down
    })
    .then((ex) => {
      // console.log('then promise all fetchAllTokens', ex);
    });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_TOKENS:
      return {
        ...state,
        tokensBlock: action.payload.tokensBlock,
        tokensCurrent: action.payload.tokensCurrent,
      };

    default:
      return state;
  }
};
