import {
  HOME_PATH,
  MARKETS_PATH,
  MARKET_ANALYSIS,
  COMING_SOON,
  PORTFOLIO,
  FAQ,
  VAULT,
  PRICE,
  LIQUIDATE,
  // BRIDGESELECTION,
  BRIDGE,
} from 'configs/route_path';
import DashboardLayout from 'containers/rifi_lending/layout/DashboardLayout';

// const routes = [
//   {
//     path: HOME_PATH,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('containers/rifi_lending/dashboard/DashboardContainer'),
//   },
//   {
//     path: MARKETS_PATH,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('containers/rifi_lending/markets/MarketsContainer'),
//   },
//   {
//     path: MARKET_ANALYSIS,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import(
//       'containers/rifi_lending/marketAnalysis/MarketAnalysisContainer'
//     ),
//   },
//   {
//     path: COMING_SOON,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
//   },
//   {
//     path: PORTFOLIO,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('containers/rifi_lending/portfolio/PortfolioContainer'),
//   },
//   {
//     path: `${MARKET_ANALYSIS}/:slug`,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/coin/Coin'),
//   },
//   {
//     path: FAQ,
//     exact: false,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import(
//       'components/rifi_lending/frequentlyQuestion/FrequentlyQuestion'
//     ),
//   },
//   {
//     path: VAULT,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/vault/Vault'),
//   },
//   {
//     path: PRICE,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('containers/rifi_lending/price/PriceContainer'),
//   },
//   {
//     path: `${VAULT}/:slug`,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/vaultDetail/VaultDetail'),
//   },
// {
//   path: `${VAULT}/nft/:id`,
//   exact: true,
//   isPrivate: false,
//   layout: DashboardLayout,
//   component: import('components/rifi_lending/vaultNFT/CardDetail'),
// },
//   {
//     path: LIQUIDATE,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/liquidate/Liquidate'),
//   },
//   {
//     path: BRIDGE,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/bridge/Bridge'),
//   },
//   {
//     path: `${BRIDGE}/history`,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/bridge/BridgeHistory'),
//   },
//   {
//     path: `${BRIDGE}/:slug`,
//     exact: true,
//     isPrivate: false,
//     layout: DashboardLayout,
//     component: import('components/rifi_lending/bridge/BridgeDetail'),
//   },
// ];

const routes = [
  {
    path: HOME_PATH,
    exact: true,
    isPrivate: false,
    layout: DashboardLayout,
    component: import('containers/rifi_lending/dashboard/DashboardContainer'),
  },
  // {
  //   path: MARKET_ANALYSIS,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import(
  //     'containers/rifi_lending/marketAnalysis/MarketAnalysisContainer'
  //   ),
  // },
  // {
  //   path: `${MARKET_ANALYSIS}/:slug`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/coin/Coin'),
  // },
  {
    path: PRICE,
    exact: true,
    isPrivate: false,
    layout: DashboardLayout,
    component: import('containers/rifi_lending/price/PriceContainer'),
  },
  // {
  //   path: `${VAULT}/nft/:id`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/vaultNFT/CardDetail'),
  // },
  {
    path: `${VAULT}/fixed-apy/:id`,
    exact: true,
    isPrivate: false,
    layout: DashboardLayout,
    component: import('components/rifi_lending/vaultFixedAPY/VaultDetail'),
  },
  // {
  //   path: `${VAULT}/:slug`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/vaultDetail/VaultDetail'),
  // },
  // {
  //   path: VAULT,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/vault/Vault'),
  // },
  // {
  //   path: `${BRIDGE}/history`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/bridge/BridgeHistory'),
  // },
  // {
  //   path: `${BRIDGE}/:slug`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/bridge/BridgeDetail'),
  // },
  // {
  //   path: BRIDGE,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('components/rifi_lending/bridge/Bridge'),
  // },

  // COMMING SOON
  {
    path: MARKETS_PATH,
    exact: true,
    isPrivate: false,
    layout: DashboardLayout,
    component: import('containers/rifi_lending/markets/MarketsContainer'),
  },
  // {
  //   path: MARKET_ANALYSIS,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: COMING_SOON,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: PORTFOLIO,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: `${MARKET_ANALYSIS}/:slug`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: FAQ,
  //   exact: false,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: VAULT,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: PRICE,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: `${VAULT}/:slug`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: `${VAULT}/nft/:id`,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
  // {
  //   path: LIQUIDATE,
  //   exact: true,
  //   isPrivate: false,
  //   layout: DashboardLayout,
  //   component: import('containers/rifi_lending/comingSoon/ComingSoonContainer'),
  // },
];

export default routes;
