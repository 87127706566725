import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import IconEtereumWallets from 'assets/rifi_lending/icons/ic_ethereum_wallets.svg';
import IconBinanceWallets from 'assets/rifi_lending/icons/ic_binance_wallets.svg';
import Storage from './storage';
import { ConnectorNames, CURRENT_CONNECTED_WALLET } from './constants';
import { getDefaultProvider } from 'ethers';

const RPC_URL_56 = 'https://bsc-dataseed.binance.org';
const RPC_URL_97 = 'https://data-seed-prebsc-1-s1.binance.org:8545';
const RPC_URL_1 =
  'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
const RPC_URL_4 =
  'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  56: RPC_URL_56,
  97: RPC_URL_97,
  1: RPC_URL_1,
  4: RPC_URL_4,
};

export const BSC_MAINNET = 'bsc_mainnet';
export const BSC_TESTNET = 'bsc_testnet';
export const MAINNET = 'mainnet';
export const RINKEBY = 'rinkeby';
export const ROPSTEN = 'ropsten';
export const KOVAN = 'kovan';

export const supportId = [56, 97];

export const getProvider = () => {
  const currentConnectedWallet = Storage.get(CURRENT_CONNECTED_WALLET);
  if (currentConnectedWallet === ConnectorNames.Coin98) {
    return window.ethereum;
  }
  if (currentConnectedWallet === ConnectorNames.MetaMask) {
    return window.ethereum;
  }
  if (currentConnectedWallet === ConnectorNames.WalletConnect) {
    return walletConnect.walletConnectProvider;
  }
  return getDefaultProvider();
};

export const NETWORKS = {
  [BSC_MAINNET]: 56,
  [BSC_TESTNET]: 97,
  [MAINNET]: 1,
  [RINKEBY]: 4,
  [ROPSTEN]: 3,
  [KOVAN]: 42,
  // goerli: 5,
};

export const NETWORKS_SUPPORT = {
  [BSC_MAINNET]: 56,
  [BSC_TESTNET]: 97,
  [MAINNET]: 1,
  [RINKEBY]: 4,
  // [ROPSTEN]: 3,
  // [KOVAN]: 42,
};

export const injected = new InjectedConnector({
  supportedChainIds: [
    NETWORKS[BSC_MAINNET],
    NETWORKS[BSC_TESTNET],
    // NETWORKS[MAINNET],
    // NETWORKS[RINKEBY],
    // NETWORKS[ROPSTEN],
    // NETWORKS[KOVAN],
    // NETWORKS['goerli'],
  ],
});

export const walletConnect = new WalletConnectConnector({
  rpc: {
    56: RPC_URLS[56],
    97: RPC_URLS[97],
    1: RPC_URLS[1],
  },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export const coin98 = new InjectedConnector({
  supportedChainIds: [
    NETWORKS[BSC_MAINNET],
    // NETWORKS[BSC_TESTNET],
    // NETWORKS[MAINNET],
    // NETWORKS[RINKEBY],
    // NETWORKS[ROPSTEN],
    // NETWORKS[KOVAN],
    // NETWORKS['goerli'],
  ],
});
export const NETWORK_INFO = {
  [NETWORKS[MAINNET]]: [
    {
      chainId: '0x1',
      chainName: 'Ethereum Main Network (Mainnet)',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      ],
      blockExplorerUrls: ['https://etherscan.io'],
    },
  ],
  [NETWORKS[BSC_TESTNET]]: [
    {
      chainId: '0x61',
      chainName: 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://data-seed-prebsc-2-s2.binance.org:8545/'],
      blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
  ],
  [NETWORKS[RINKEBY]]: [
    {
      chainId: '0x4',
      chainName: 'Rinkeby',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      ],
      blockExplorerUrls: ['https://rinkeby.etherscan.io'],
    },
  ],
  [NETWORKS[BSC_MAINNET]]: [
    {
      chainId: '0x38',
      chainName: 'Smart Chain',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed.binance.org/'],
      blockExplorerUrls: ['https://bscscan.com'],
    },
  ],
};

export const NETWORK_ICON = {
  [NETWORKS[MAINNET]]: {
    icon: IconEtereumWallets,
    name: 'Ethereum Main Network',
  },
  [NETWORKS[RINKEBY]]: {
    icon: IconEtereumWallets,
    name: 'Rinkeby Network',
  },
  [NETWORKS[KOVAN]]: {
    icon: IconEtereumWallets,
    name: 'Kovan',
  },
  [NETWORKS[ROPSTEN]]: {
    icon: IconEtereumWallets,
    name: 'Ropsten',
  },
  [NETWORKS[BSC_TESTNET]]: {
    icon: IconBinanceWallets,
    name: 'BSC Testnet',
  },
  [NETWORKS[BSC_MAINNET]]: {
    icon: IconBinanceWallets,
    name: 'BSC Mainnet',
  },
};

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[97],
  appName: 'web3-react example',
});

export const ledger = new LedgerConnector({
  chainId: 56,
  url: RPC_URLS[56],
  pollingInterval: POLLING_INTERVAL,
});
