import React from 'react';
import RiFiBranding from 'assets/rifi_lending/sidebar/rifi_branding.svg';
import { ReactComponent as IconDashboard } from 'assets/rifi_lending/sidebar/dashboard_icon.svg';
import { ReactComponent as IconMarket } from 'assets/rifi_lending/sidebar/market_icon.svg';
import { ReactComponent as IconAnalysis } from 'assets/rifi_lending/sidebar/analysis_icon.svg';
import { ReactComponent as IconPrice } from 'assets/rifi_lending/sidebar/price_icon.svg';
import { ReactComponent as IconVault } from 'assets/rifi_lending/sidebar/vault_icon.svg';
import { ReactComponent as IconBridge } from 'assets/rifi_lending/sidebar/bridge_icon.svg';
import { ReactComponent as IconFAQ } from 'assets/rifi_lending/sidebar/faq_icon.svg';
import { ReactComponent as IconTelegram } from 'assets/rifi_lending/sidebar/Telegram.svg';
import { ReactComponent as IconTwitter } from 'assets/rifi_lending/sidebar/Twitter.svg';
import { ReactComponent as IconMedium } from 'assets/rifi_lending/sidebar/Medium.svg';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import {
  DASHBOARD_PATH,
  MARKETS_PATH,
  MARKET_ANALYSIS,
  PRICE,
  // GOVERNANCE,
  // CONTACT,
  // FEEDBACK,
  // WHITEPAPER,
  // COMING_SOON,
  FAQ,
  VAULT,
  BRIDGE,
} from 'configs/route_path';

const Sidebar = ({ showOnMobile }) => {
  return (
    <Navbar className={`menu-sidebar menu-sidebar--${showOnMobile}`}>
      <Navbar.Brand className='menu-sidebar__branding'>
        <a href={'https://rikkei.finance/'}>
          <img src={RiFiBranding} alt='Rifi branding'></img>
        </a>
      </Navbar.Brand>
      <div className='sidebar-menu-items'>
        <Nav className='nav-sidebar'>
          <NavLink to={DASHBOARD_PATH} exact className='nav-sidebar__item'>
            <IconDashboard className='nav-sidebar__icon' alt='dashboard' />
            <span className='nav-sidebar__name'>Dashboard</span>
          </NavLink>
          <NavLink to={MARKETS_PATH} className='nav-sidebar__item'>
            <IconMarket className='nav-sidebar__icon' alt='market' />
            <span className='nav-sidebar__name'>Markets</span>
          </NavLink>
          <NavLink to={PRICE} className='nav-sidebar__item'>
            <IconPrice className='nav-sidebar__icon' alt='price' />
            <span className='nav-sidebar__name'>Price</span>
          </NavLink>
          {/* <NavLink to={MARKET_ANALYSIS} className='nav-sidebar__item'>
            <IconAnalysis
              className='nav-sidebar__icon nav-sidebar__icon--stroke'
              alt='analysis'
            />
            <span className='nav-sidebar__name'>Analysis</span>
          </NavLink> */}
          {/* <NavLink to={VAULT} className='nav-sidebar__item'>
            <IconVault
              className='nav-sidebar__icon nav-sidebar__icon--stroke'
              alt='governance'
            />
            <span className='nav-sidebar__name'>Vault</span>
          </NavLink>
          <NavLink to={BRIDGE} className='nav-sidebar__item'>
            <IconBridge className='nav-sidebar__icon' alt='governance' />
            <span className='nav-sidebar__name'>Bridge</span>
          </NavLink> */}
        </Nav>

        <Nav className='nav-sidebar nav-sidebar--bottom'>
          {/* <NavLink to={FAQ} className='nav-sidebar__item'>
            <IconFAQ
              className='nav-sidebar__icon nav-sidebar__icon--stroke'
              alt='FAQ'
            />
            <span className='nav-sidebar__name'>FAQ</span>
          </NavLink> */}
          <div className='social-media'>
            <a
              className='social-media__icon'
              href='https://t.me/RikkeiFinance'
              target='_blank'
              rel='noreferrer'
            >
              <IconTelegram className='nav-sidebar__icon' alt='telegram' />
            </a>
            <a
              className='social-media__icon'
              href='https://twitter.com/rikkeifinance'
              target='_blank'
              rel='noreferrer'
            >
              <IconTwitter className='nav-sidebar__icon' alt='twitter' />
            </a>
            <a
              className='social-media__icon'
              href='https://medium.com/rikkeifinance'
              target='_blank'
              rel='noreferrer'
            >
              <IconMedium className='nav-sidebar__icon' alt='medium' />
            </a>
          </div>
        </Nav>
      </div>
    </Navbar>
  );
};

export default Sidebar;
